// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-advisers-and-service-providers-tsx": () => import("./../../../src/pages/about/advisers-and-service-providers.tsx" /* webpackChunkName: "component---src-pages-about-advisers-and-service-providers-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-eea-life-settlements-fund-tsx": () => import("./../../../src/pages/eea-life-settlements-fund.tsx" /* webpackChunkName: "component---src-pages-eea-life-settlements-fund-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-info-page-info-page-tsx": () => import("./../../../src/templates/InfoPage/InfoPage.tsx" /* webpackChunkName: "component---src-templates-info-page-info-page-tsx" */),
  "component---src-templates-news-page-tsx": () => import("./../../../src/templates/NewsPage.tsx" /* webpackChunkName: "component---src-templates-news-page-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

